import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import * as _api from "../../Apis/Criteres_apis";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import ModalCritereView from "./ModalCritereView";
import SousCriteres from "./SousCriteres";
import { BsPencil, BsPlusLg } from "react-icons/bs";

const Criteres = (props) => {
  const user = useContext(userContext);
  const { keycloak, initialized } = useKeycloak();
  const [loading, setLoading] = useState(false);
  const [criteres, setCriteres] = useState();
  const [critere, setCritere] = useState();
  const [showModalCritere, setShowModalCritere] = useState(false);
  const handleModalCritere = (status) => setShowModalCritere(status); //fonction à passer au child pour MAJ status modal

  const majFromModal = (data, action) => {
    let arrayTemp = [{}];
    switch (action) {
      case "delete":
        const deleteList = criteres.filter((item) => item.id !== data.id);
        setCriteres(deleteList);
        break;
      case "add":
        arrayTemp = criteres;
        arrayTemp.push(data);
        setCriteres(arrayTemp);
        break;
      case "update":
        let userIndex = criteres.findIndex((obj) => obj.id == data.id);
        arrayTemp = criteres;
        arrayTemp[userIndex] = data;
        setCriteres(arrayTemp);
        break;
      default:
        break;
    }
  };

  // On récupère les critères du bareme si existant
  useEffect(() => {
    if (props.baremeId) {
      setLoading(true);
      _api
        .getCriteresAsync(keycloak.token, props.baremeId)
        .then((res) => {
          setCriteres(res);
        })
        .then(() => setLoading(false));
    }
  }, [props.baremeId, props.username]);

  const listCriteres = () => {
    return criteres.sort((a, b) => (a.letter > b.letter) ? 1 : -1).map((critere) => {
      return (
        <Card className="card-critere">
          <Card.Header className="header-card-critere">
            <Row>
              <Col sm={10}>
                <span
                  className="round_critere"
                  style={{
                    background: critere.color,
                  }}
                ></span>
                {critere.letter} - {critere.name}
              </Col>
              <Col sm={2}>
                {user.userRights && user.userRights.cis.update && (
                  <a
                    href="#"
                    onClick={() => {
                      setShowModalCritere(true);
                      setCritere(critere);
                    }}
                  >
                    <BsPencil />
                  </a>
                )}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <SousCriteres
              username={props.username}
              date={props.date}
              id={critere.id}
            />
          </Card.Body>
        </Card>
      );
    });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      {showModalCritere && (
        <ModalCritereView
          show={handleModalCritere}
          id={props.baremeId}
          data={critere}
          maj={majFromModal}
        />
      )}
      <Row xs={1} md={2}>
        {criteres && listCriteres()}
      </Row>

      {user.userRights && user.userRights.cis.create && (
        <Card style={{width: "20%"}}
          className="card-critere"
          onClick={() => {
            setShowModalCritere(true);
            setCritere("");
          }}
        >
          <Button 
              size="sm"
              variant="outline-primary"><BsPlusLg /> Ajouter un critère</Button>
        </Card>
      )}
    </>
  );
};

export default Criteres;
